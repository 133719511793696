import React, { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { useParams } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function UserUpdate() {
  const classes = useStyles();

  const { idin } = useParams();
  var jsondata = {
    'idin': idin
  };
  useEffect(() => {
    fetch("https://timotheou.ddns.net/rpc/getcompany",{
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(jsondata),
    })
      .then(response => response.json())
      .then(
        (result) => {
          setId(+(result.map(( {company}) => company).map(( {id}) => id)))       
          setDescr(result.map(( {company}) => company).map(( {descr}) => descr).toString())
          setAfm(result.map(( {company}) => company).map(( {afm}) => afm).toString())
          setArmae(result.map(( {company}) => company).map(( {armae}) => armae).toString())
          setEpagId(+(result.map(( {company}) => company).map(( {epag_id}) => epag_id)))
          setAddress(result.map(( {company}) => company).map(( {address}) => address).toString())
          setTk(result.map(( {company}) => company).map(( {tk}) => tk).toString())
          setAreaId(+(result.map(( {company}) => company).map(( {area_id}) => area_id)))
          setCityId(+(result.map(( {company}) => company).map(( {city_id}) => city_id)))
          setCountryId(+(result.map(( {company}) => company).map(( {country_id}) => country_id)))
          setTelephone1(result.map(( {company}) => company).map(( {telephone1}) => telephone1).toString())
          setTelephone2(result.map(( {company}) => company).map(( {telephone2}) => telephone2).toString())
          setFax(result.map(( {company}) => company).map(( {fax}) => fax).toString())
          setEmail(result.map(( {company}) => company).map(( {email}) => email).toString())
          setWebsite(result.map(( {company}) => company).map(( {website}) => website).toString())
          setContactPerson(result.map(( {company}) => company).map(( {contact_person}) => contact_person).toString())          
        }
      )
  },[idin])
 


  function handleSubmit(event) {
    event.preventDefault();
    var jsondata = {
      'id':id,
      'descr':descr,
      'afm':afm,
      'armae':armae,
      'epag_id':epagId,
      'address':address,
      'tk':tk,
      'area_id':areaId,
      'city_id':cityId,
      'country_id':countryId,
      'telephone1':telephone1,
      'telephone2':telephone2,
      'fax':fax,
      'email':email,
      'website':website,
      'contact_person':contactPerson
    };
    if (window.confirm('Θέλετε να ενημερωθούν τα στοιχεία της εταιρείας ' + id+';')) {
      const updateData = async () => {
        const response = await fetch('https://timotheou.ddns.net/companies?id=eq.'+id, {
      method: 'PATCH',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(jsondata),
    })
    const data = await response.status===204
    
    console.log(data)
    if (data) {
      alert('Η ενημέρωση ήταν επιτυχής')
    }
    else {alert('Η ενημέρωση απέτυχε')
   } 
  }
  updateData();       

} else {
  // Do nothing!
  console.log('Thing was not saved to the database.');
}

  }

  const [id, setId] = useState('');
  const [descr, setDescr] = useState('');
  const [afm, setAfm] = useState('');
  const [armae, setArmae] = useState('');
  const [epagId, setEpagId] = useState('');
  const [address, setAddress] = useState('');
  const [tk, setTk] = useState('');
  const [areaId, setAreaId] = useState('');
  const [cityId, setCityId] = useState('');
  const [countryId, setCountryId] = useState('');
  const [telephone1, setTelephone1] = useState('');
  const [telephone2, setTelephone2] = useState('');
  const [fax, setFax] = useState('');
  const [email, setEmail] = useState('');
  const [website, setWebsite] = useState('');
  const [contactPerson, setContactPerson] = useState('');
  

  return (
    <Container maxWidth='lg'>
      <div className={classes.paper}>
        <Typography component="h1" variant="h5">
          Στοιχεία Εταιρείας
        </Typography>
        <form className={classes.form} onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs="auto" sm={2}>
              <TextField
                autoComplete="id"
                name="id"
                variant="outlined"
                required
                fullWidth
                id="id"
                label="Κωδ. Εταιρείας"
                value={id}
                onChange={(e) => setId(e.target.value)}
                autoFocus
                disabled="true"
              />
            </Grid>
            <Grid item xs="auto" sm={10}>
              <TextField
                autoComplete="descr"
                name="descr"
                variant="outlined"
                required
                fullWidth
                id="descr"
                label="Επωνυμία"
                value={descr}
                onChange={(e) => setDescr(e.target.value)}
                autoFocus
              />
            </Grid> 
            <Grid item xs="auto" sm={8}>
              <TextField
                autoComplete="epagId"
                name="epagId"
                variant="outlined"
                required
                fullWidth
                id="epagId"
                label="Επάγγελμα"
                value={epagId}
                onChange={(e) => setEpagId(e.target.value)}
                autoFocus
              />
            </Grid> 
            <Grid item xs="auto" sm={2}>
              <TextField
                autoComplete="afm"
                name="afm"
                variant="outlined"
                required
                fullWidth
                id="afm"
                label="Α.Φ.Μ."
                value={afm}
                onChange={(e) => setAfm(e.target.value)}
                autoFocus
              />
            </Grid>  
            <Grid item xs="auto" sm={2}>
              <TextField
                autoComplete="armae"
                name="armae"
                variant="outlined"
                fullWidth
                id="armae"
                label="ΑΡ.Μ.ΑΕ"
                value={armae}
                onChange={(e) => setArmae(e.target.value)}
                autoFocus
              />
            </Grid>    
            <Grid item xs="auto" sm={10}>
              <TextField
                autoComplete="address"
                name="address"
                variant="outlined"
                required
                fullWidth
                id="address"
                label="Διεύθυνση"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                autoFocus
              />
            </Grid>                        
            <Grid item xs="auto" sm={2}>
              <TextField
                autoComplete="tk"
                name="tk"
                variant="outlined"
                required
                fullWidth
                id="tk"
                label="T.K."
                value={tk}
                onChange={(e) => setTk(e.target.value)}
                autoFocus
              />
            </Grid> 
            <Grid item xs="auto" sm={4}>
              <TextField
                autoComplete="areaId"
                name="areaId"
                variant="outlined"
                required
                fullWidth
                id="areaId"
                label="Περιοχή"
                value={areaId}
                onChange={(e) => setAreaId(e.target.value)}
                autoFocus
              />
            </Grid> 
            <Grid item xs="auto" sm={4}>
              <TextField
                autoComplete="cityId"
                name="cityId"
                variant="outlined"
                required
                fullWidth
                id="cityId"
                label="Πόλη"
                value={cityId}
                onChange={(e) => setCityId(e.target.value)}
                autoFocus
              />
            </Grid>  
            <Grid item xs="auto" sm={4}>
              <TextField
                autoComplete="countryId"
                name="countryId"
                variant="outlined"
                required
                fullWidth
                id="countryId"
                label="Χώρα"
                value={countryId}
                onChange={(e) => setCountryId(e.target.value)}
                autoFocus
              />
            </Grid>
            <Grid item xs="auto" sm={4}>
              <TextField
                autoComplete="telephone1"
                name="telephone1"
                variant="outlined"
                required
                fullWidth
                id="telephone1"
                label="Τηλέφωνο 1"
                value={telephone1}
                onChange={(e) => setTelephone1(e.target.value)}
                autoFocus
              />
            </Grid>    
            <Grid item xs="auto" sm={4}>
              <TextField
                autoComplete="telephone2"
                name="telephone2"
                variant="outlined"
                fullWidth
                id="telephone2"
                label="Τηλέφωνο 2"
                value={telephone2}
                onChange={(e) => setTelephone2(e.target.value)}
                autoFocus
              />
            </Grid>  
            <Grid item xs="auto" sm={4}>
              <TextField
                autoComplete="fax"
                name="fax"
                variant="outlined"
                fullWidth
                id="fax"
                label="fax"
                value={fax}
                onChange={(e) => setFax(e.target.value)}
                autoFocus
              />
            </Grid>
            <Grid item xs="auto" sm={6}>
              <TextField
                autoComplete="email"
                name="email"
                variant="outlined"
                fullWidth
                id="email"
                label="e-mail"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                autoFocus
              />
            </Grid> 
            <Grid item xs="auto" sm={6}>
              <TextField
                autoComplete="website"
                name="website"
                variant="outlined"
                fullWidth
                id="website"
                label="Ιστοσελίδα"
                value={website}
                onChange={(e) => setWebsite(e.target.value)}
                autoFocus
              />
            </Grid> 
            <Grid item xs="auto" sm={12}>
              <TextField
                autoComplete="contactPerson"
                name="contactPerson"
                variant="outlined"
                fullWidth
                id="contactPerson"
                label="Στοιχεία υπευθύνου"
                value={contactPerson}
                onChange={(e) => setContactPerson(e.target.value)}
                autoFocus
              />
            </Grid>   
            <Grid item xs="auto" sm={6}>
          <Button
            type="submit"
            size="large"
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Update
          </Button>
            </Grid>                                                                                           
          </Grid>
            
          
          
        </form>
      </div>
    </Container>
  );
}