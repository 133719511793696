import React, { useEffect, useState } from "react";
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  container: {
    marginTop: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
}));

export default function CompaniesList() {
  const classes = useStyles();

  const [companies, setCompanies] = useState([]);
  useEffect(() => {
    CompaniesGet()
  }, [])
  
  const CompaniesGet = () => {
    fetch("https://timotheou.ddns.net/companies")
      .then(res => res.json())
      .then(
        (result) => {
          setCompanies(result)
        }
      )
  }

  const CompanyUpdate = id => {
    window.location = '/update/'+id
  }

  const CompanyDelete = id => {


    if (window.confirm('Θέλετε να προχωρήσετε στη διαγραφή της Εταιρείας ' + id+';')) {
      const getData = async () => {
        const response = await fetch('https://timotheou.ddns.net/companies?id=eq.'+id, {
          method: 'DELETE',
        })
        const data = await response.status===204
        
        if (data) {
          alert('Η διαγραφή ήταν επιτυχής')
        }
        else
        {
          alert('Η διαγραφή απέτυχε');
        }
      }
      getData();
      
            

    } else {
      // Do nothing!
      console.log('Thing was not saved to the database.');
    }
        

    CompaniesGet();   
    
  }

  return (
    <div className={classes.root}>
      <Container className={classes.container} maxWidth="lg">    
        <Paper className={classes.paper}>
          <Box display="flex">
            <Box flexGrow={1}>
              <Typography component="h2" variant="h6" color="primary" gutterBottom>
                Αρχείο Εταιρειών
              </Typography>
            </Box>
            <Box>
              <Link to="/create">
                <Button variant="contained" color="primary">
                  ΠΡΟΣΘΗΚΗ
                </Button>
              </Link>
            </Box>
          </Box>
          <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="right">Κωδικός</TableCell>
                <TableCell align="left">Επωνυμία</TableCell>
                <TableCell align="left">ΑΦΜ</TableCell>
                <TableCell align="left">ΑΡΜΑΕ</TableCell>
                <TableCell align="left">Τηλέφωνο</TableCell>                
                <TableCell align="center">Ενέργειες</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {companies.map((companies) => (
                <TableRow key={companies.ID}>
                  <TableCell align="right">{companies.id}</TableCell>
                  <TableCell align="left">{companies.descr}</TableCell>
                  <TableCell align="left">{companies.afm}</TableCell>
                  <TableCell align="left">{companies.armae}</TableCell>
                  <TableCell align="left">{companies.telephone1}</TableCell>
                  <TableCell align="center">
                    <ButtonGroup color="primary" aria-label="outlined primary button group">
                      <Button onClick={() => CompanyUpdate(companies.id)}>View/Edit</Button>
                      <Button onClick={() => CompanyDelete(companies.id)}>Del</Button>
                    </ButtonGroup>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        </Paper>
      </Container>
    </div>
    
  );
}