import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Navbar from './Navbar'
import Companies from './Companies'
import CompanyUpdate from './CompanyUpdate'

export default function App() {
  return (
    <Router>
       <div>
        <Navbar />

        <Routes>
          <Route exact path="/" element={<Companies/>}/>
          <Route exact path="/update/:idin" element={<CompanyUpdate/>}/>
        </Routes>

      </div>      
    </Router>
  );
}